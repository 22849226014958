import { combineReducers } from 'redux'

const initialState = { default: true };
const defaultReducer = (state = initialState, action) => {
    switch (action.type) {
        case true:
            return { ...state };
        default:
            return state;
    }
};

const rootReducer = combineReducers({ default: defaultReducer });
export default rootReducer;